import React from 'react'
import { Link } from 'gatsby'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'
import useSiteMetadata from '@helpers/useSiteMetadata'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, `wrap`, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`]
  }
}

export default props => {
  const { topic } = useSiteMetadata()

  return (
    <>
      <Seo title='Semi-Absentee Franchises' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='Semi-Absentee Franchises'
            subheader={`Our expertise can help you choose the right Semi-Absentee ${topic} Franchise.`}
          />
          <Divider />         
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              
              <Section title='What is a Semi-Absentee Franchise?'>
                <Text variant='p'>
                  <strong>Semi-Absentee franchise</strong> ownership is sometimes described as a 
                  <em><strong> Passive Income Franchise Opportunity</strong></em>. 
                </Text>
                
                <Text variant='p'>
                  Operational responsibility falls somewhere in between Executive (hands-off) 
                  and Owner/Operator (hands-on).                  
                </Text>

                <Text variant='p'>
                  Many semi-absentee franchise owners begin by keeping their day jobs/careers 
                  by relying on a manager to get their first store off the ground.
                </Text>

                <Text variant='p'>
                  If the franchisee succeeds with their first unit and decides to open additional ones, 
                  running the multi-unit franchise may prove lucrative enough for the semi-absentee 
                  owner to leave their day job/career and focus solely on the growing franchise business.
                </Text>
              </Section>

            </Box>
            <Box sx={styles.column}>
              
              <Section title='Choosing the Right Franchise'>
                <Card variant='paper'>

                  <Text variant='p'>
                    Our expertise and relationships in the <strong>Semi-Absentee Franchise</strong> industry 
                    can help you find the best opportunities.
                  </Text>

                  <Text variant='p'>
                    More importantly, we get to know your situation and needs to match 
                    you with the right franchises.
                  </Text>
                
                  <Button as={Link} to='/franchise-tag/semi-absentee/'>
                    View Semi-Absentee Franchises
                  </Button>
                </Card>

              </Section>
              
            </Box>
          </Flex>
        </Main>
      </Stack>
    </>
  )
}

